/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AppBar,
  Box, Container, Grid, IconButton, Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { AccountCircle as AccountCircleIcon } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import {
  useCallback, useMemo, useState,
} from 'react'
import { Link, useHistory } from 'react-router-dom'

import { useSession } from '../../contexts/SessionContext'
import { LoginDialog, RegisterDialog, ResetPasswordDialog } from '../Dialogs/UserDialog'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
    justifyContent: 'start',
  },
  mainMenu: {
    textAlign: 'center',
    flexGrow: 1,
    justifyContent: 'start',
  },
  navLink: {
    '& > * + *': {
      marginLeft: theme.spacing(2),
      color: '#000',
    },
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'rgb(86, 60, 100)',
  },
  content: {
    minHeight: 'calc(100vh - 219px)',
  },
  footer: {
    padding: '50px 20px',
    backgroundColor: 'rgb(221,229,246)',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    textAlign: 'center',
  },
}))

function Layout(props) {
  const history = useHistory()
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })
  // const [drawer, setDrawer] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [loading, setLoading] = useState(false)
  const { children } = props
  const {
    login, logout, user, register, resetPassword,
  } = useSession()
  const classes = useStyles()
  const menu = Boolean(anchorEl)
  const { enqueueSnackbar } = useSnackbar()

  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )
  const handleLogin = useCallback(
    async (data) => {
      setLoading(true)
      try {
        if (data.reCaptcha) {
          await login(data)
          enqueueSnackbar('เข้าสู่ระบบสำเร็จ', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
          setLoading(false)
          closeDialog()
        }
      } catch (err) {
        setLoading(false)
        enqueueSnackbar(err, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      }
    }, [login, closeDialog, enqueueSnackbar],
  )
  const handleRegister = useCallback(
    async (data) => {
      setLoading(true)
      try {
        if (data.reCaptcha) {
          await register(data)
          enqueueSnackbar('ลงทะเบียนสำเร็จ', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
          setLoading(false)
          closeDialog()
        }
      } catch (err) {
        setLoading(false)
        enqueueSnackbar(err, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      }
    }, [closeDialog, register, enqueueSnackbar],
  )
  const resetPasswordDialog = useCallback(
    () => {
      closeDialog()
      openDialog('resetPassword')()
    }, [openDialog, closeDialog],
  )
  const handleResetPassword = useCallback(
    async (data) => {
      await resetPassword(data)
      enqueueSnackbar('กรุณาเช็คอีเมลของท่าน', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      closeDialog()
      openDialog('login', data.email)()
    }, [resetPassword, closeDialog, openDialog, enqueueSnackbar],
  )
  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'login') {
          return <LoginDialog onClose={closeDialog} openDialog={openDialog} onSubmit={handleLogin} resetPassword={resetPasswordDialog} email={dialog.data} loading={loading} />
        }
        if (dialog.mode === 'register') {
          return <RegisterDialog onSubmit={handleRegister} onClose={closeDialog} openDialog={openDialog} loading={loading} />
        }
        if (dialog.mode === 'resetPassword') {
          return <ResetPasswordDialog onSubmit={handleResetPassword} onClose={closeDialog} />
        }
      }
      return null
    },
    [closeDialog, dialog, openDialog, handleLogin, handleRegister, handleResetPassword, resetPasswordDialog, loading],
  )

  // const toggleDrawer = useCallback(
  //   () => {
  //     setDrawer(!drawer)
  //   },
  //   [drawer],
  // )

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const handleClickMenu = useCallback(
    (type, dest) => async () => {
      setAnchorEl(null)
      if (type === 'dialog') {
        openDialog(dest)()
      }
      if (type === 'link') {
        history.push(dest)
      }
      if (type === 'logout') {
        try {
          await logout()
          enqueueSnackbar('ออกจากระบบสำเร็จ', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        } catch (err) {
          enqueueSnackbar('ออกจากระบบไม่สำเร็จ กรุณาแจ้งแอดมิน', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          })
        }
      }
    }, [openDialog, history, logout, enqueueSnackbar],
  )
  return (
    <div className={classes.root}>
      <AppBar position="static" color="transparent">
        <Toolbar>
          {/* <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <IconButton
              edge="start"
              color="inherit"
              className={classes.menuButton}
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          </Hidden> */}
          {/* <Hidden xsDown> */}
          <Typography className={`${classes.navLink} ${classes.mainMenu}`} noWrap>
            <MainMenu />
          </Typography>
          {/* </Hidden> */}
          <IconButton
            aria-label="search"
            color="inherit"
            edge="end"
            size="small"
            onClick={handleMenu}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            open={menu}
            anchorEl={anchorEl}
            onClose={handleClickMenu()}
          >
            {!user
              ? (
                <div>
                  <MenuItem onClick={handleClickMenu('dialog', 'login')}>เข้าสู่ระบบ</MenuItem>
                  <MenuItem onClick={handleClickMenu('dialog', 'register')}>ลงทะเบียน</MenuItem>
                </div>
              )
              : (
                <div>
                  {user.role === 'admin' && <MenuItem onClick={handleClickMenu('link', '/review-paper')}>รีวิวงานวิจัย</MenuItem>}
                  {user.role === 'admin' && <MenuItem onClick={handleClickMenu('link', '/all')}>งานวิจัยทั้งหมด</MenuItem>}
                  {user.role === 'admin' && <MenuItem onClick={handleClickMenu('link', '/users')}>จัดการผู้ใช้</MenuItem>}
                  {user.role === 'editor' && <MenuItem onClick={handleClickMenu('link', '/paper')}>งานวิจัยของฉัน</MenuItem>}
                  <MenuItem onClick={handleClickMenu('logout')}>ออกจากระบบ</MenuItem>
                </div>
              )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Box className={classes.content}>
        {children}
        {renderDialog}
      </Box>
      <Footer />
      {/* <Drawer anchor="left" open={drawer} onClose={toggleDrawer}>
        <div style={{ width: 250 }} onClick={toggleDrawer}>
          <List>
            {['หน้าแรก', 'กลุ่มเทคโนโลยี', 'ผลผลิตโครงการ', 'เกี่ยวกับเรา', 'ติดต่อ'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer> */}
    </div>
  )
}

const Footer = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="lg" className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item md={5} xs={12}>
          <span>ลิขสิทธิ์ © 2564 กระทรวงอุตสาหกรรม. All rights reserved</span>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography className={classes.navLink}>
            <MainMenu />
          </Typography>
        </Grid>
        <Grid item md={1} xs={12}>
          <Box display="flex" justifyContent="center">
            <a href="https://www.facebook.com/industryprmoi/" target="_blank" rel="noreferrer">
              <img src="/assets/icon-facebook.png" alt="icon" width="25" style={{ margin: '0 2px', borderRadius: 40 }} />
            </a>
            <a href="https://twitter.com/industryprmoi" target="_blank" rel="noreferrer">
              <img src="/assets/icon-twitter.png" alt="icon" width="25" style={{ margin: '0 2px', borderRadius: 40 }} />
            </a>
            <a href="https://www.youtube.com/channel/UCJ67uBVmBOpB-HzBmF4Cy6g" target="_blank" rel="noreferrer">
              <img src="/assets/icon-youtube.png" alt="icon" width="25" style={{ margin: '0 2px', borderRadius: 40 }} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}

const MainMenu = () => {
  const history = useHistory()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const menu = Boolean(anchorEl)

  const handleClickMenu = useCallback(
    (dest) => async () => {
      setAnchorEl(null)
      if (dest) {
        history.push(`/#${dest}`, {
          category: dest,
        })
        // return <Redirect to={{
        //   to: '/',
        //   state: { category: dest }
        // }}/>
      }
    }, [history],
  )
  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }
  return (
    <>
      <Link className={classes.link} to="/">หน้าแรก</Link>
      <span className={classes.link} onClick={handleMenu}>กลุ่มเทคโนโลยี</span>
      <Link className={classes.link} to="/about">เกี่ยวกับเรา</Link>
      {/* <Link className={classes.link} to="/">ผลผลิตโครงการ</Link> */}
      {/* <Link className={classes.link} to="/">ติดต่อ</Link> */}
      <Menu
        open={menu}
        anchorEl={anchorEl}
        onClose={handleClickMenu()}
      >
        <MenuItem onClick={handleClickMenu('อาหารและเครื่องดื่ม')}>อาหารและเครื่องดื่ม</MenuItem>
        <MenuItem onClick={handleClickMenu('เกษตร/ประมง')}>เกษตร/ประมง</MenuItem>
        <MenuItem onClick={handleClickMenu('ไฟฟ้า/อิเล็กทรอนิกส์/ดิจิทัล')}>ไฟฟ้า/อิเล็กทรอนิกส์/ดิจิทัล</MenuItem>
        <MenuItem onClick={handleClickMenu('เวชสำอาง/เวชภัณฑ์')}>เวชสำอาง/เวชภัณฑ์</MenuItem>
        <MenuItem onClick={handleClickMenu('การแพทย์')}>การแพทย์</MenuItem>
        <MenuItem onClick={handleClickMenu('เครื่องจักรและยานยนต์')}>เครื่องจักรและยานยนต์</MenuItem>
        <MenuItem onClick={handleClickMenu('เคมีภัณฑ์')}>เคมีภัณฑ์</MenuItem>
        <MenuItem onClick={handleClickMenu('กระดาษและการพิมพ์')}>กระดาษและการพิมพ์</MenuItem>
        <MenuItem onClick={handleClickMenu('พลาสติก')}>พลาสติก</MenuItem>
        <MenuItem onClick={handleClickMenu('สิ่งทอ')}>สิ่งทอ</MenuItem>
        <MenuItem onClick={handleClickMenu('การตลาด/ประชาสัมพันธ์')}>การตลาด/ประชาสัมพันธ์</MenuItem>
        <MenuItem onClick={handleClickMenu('เชื้อเพลิงชีวภาพ')}>เชื้อเพลิงชีวภาพ</MenuItem>
      </Menu>
    </>
  )
}

export default Layout

