import {
  Button,
  Dialog,
  DialogActions, DialogContent, DialogTitle, Typography,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

export const ApproveDialog = (props) => {
  const { paper, onSubmit, onClose } = props
  const { handleSubmit } = useForm({ defaultValues: paper })
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>อนุมัติงานวิจัย</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ยืนยันอนุมัติ ({paper.paperName}) ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            อนุมัติ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export const RejectDialog = (props) => {
  const { paper, onSubmit, onClose } = props
  const { handleSubmit } = useForm({ defaultValues: paper })
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>ปฏิเสธงานวิจัย</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ปฏิเสธอนุมัติ ({paper.paperName}) ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            ปฏิเสธ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
