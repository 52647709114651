import {
  Box, Container, makeStyles, Paper, Typography,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  mainFeaturedPost: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 13,
  },
  header: {
    color: 'rgb(87, 24, 133)',
  },
  content: {
    marginTop: 12,
    '& p': {
      textIndent: 12,
    },
  },
}))
const AboutUsPage = () => {
  const classes = useStyles()
  return (
    <Container maxWidth="md" style={{ marginTop: 54 }}>
      <Paper style={{ padding: 20 }}>
        <img className={classes.mainFeaturedPost} src="/assets/cover-2.png" alt="/assets/cover-2.png" width="100%" />
        <Typography className={classes.header} variant="h5">เกี่ยวกับเรา</Typography>
        <Box className={classes.content}>
          <p>
            เว็บไชต์ SMEs Research Connect เป็นเว็บไซต์สนับสนุนการดำเนินโครงการกิจกรรมให้คำปรึกษาแนะนำด้านการเพิ่มมูลค่าผลิตภัณฑ์จากงานวิจัย เทคโนโลยีและนวัตกรรมและให้คำปรึกษาแนะนำเชิงลึกด้านการนำผลิตภัณฑ์เพื่อให้เกิดต้นแบบผลิตภัณฑ์จากการเชื่อมโยงงานวิจัยเทคโนโลยี และนวัตกรรม เตรียมพร้อมสู่เชิงพาณิชย์ ได้รับการสนับสนุนการดำเนินงานโดยสำนักงานปลัดกระทรวงอุตสาหกรรม ดำเนินการโดยมหาวิทยาลัยราชภัฎสวนสุนันทา วิทยาเขตนครปฐม
          </p>
          <p>
            ทั้งนี้แพลตฟอร์ม SMEs Research Connect มุ่งเน้นให้บริการด้านการรวบรวมข้อมูลวิจัย รวบรวม และคัดเลือกงานวิจัย ที่สอดคล้อง กับเทคโนโลยีและนวัตกรรมจากหน่วยงานที่เกี่ยวข้อง อาทิ อุตสาหกรรมยานยนต์ไทย อุตสาหกรรมเทคโนโลยีเครื่องมือแพทย์ไทย อุตสาหกรรมผู้ผลิตอาหารสำเร็จรูป อุตสาหกรรมผู้ผลิตเครื่องสำอางไทย  สำหรับนำเสนอรูปแบบงานวิจัย เทคโนโลยีและนวัตกรรม รวมทั้งเผยแพร่ผ่านช่องทางออนไลน์ ตลอดจนช่วยอำนวยความสะดวกให้แก่ผู้ประกอบการเอสเอ็มอีที่เกี่ยวข้องให้สามารถเข้าถึงและใช้ประโยชน์จากเทคโนโลยี ผลงานวิจัย ทรัพย์สินทางปัญญาและบริการจากแพลตฟอร์มที่พัฒนาขึ้นต่อไป
          </p>
        </Box>
      </Paper>
    </Container>
  )
}
export default AboutUsPage
