import * as firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAcDuA02HKjMNdGuPng0hq725NlxV3E_sU',
  authDomain: 'sme-research-connect.firebaseapp.com',
  projectId: 'sme-research-connect',
  storageBucket: 'sme-research-connect.appspot.com',
  messagingSenderId: '1040200223439',
  appId: '1:1040200223439:web:3601b0d15439f8ec7ac5ba',
  measurementId: 'G-TFKP0LNPEX',
}

firebase.initializeApp(firebaseConfig)

export default firebase
