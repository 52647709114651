import {
  Box, Button, Container, Grid, Paper, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  doc, getDoc, getFirestore, updateDoc,
} from 'firebase/firestore'
import { useSnackbar } from 'notistack'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useParams } from 'react-router-dom'

import Loading from '../components/Common/Loading'
import { LightBoxDialog } from '../components/Dialogs/LighboxDialog'
import { ApproveDialog, RejectDialog } from '../components/Dialogs/ReviewDialog'
import { useSession } from '../contexts/SessionContext'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(20),
    right: theme.spacing(6),
  },
  topImage: {
    maxWidth: '100%',
    maxHeight: '600px',
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    borderRadius: 5,
  },
  topic: {
    color: 'rgb(87, 24, 133)',
  },
  content: {
    marginLeft: 20,
  },
  caption: {
    color: 'gray',
  },
  layout_img: {
    width: '100%',
    paddingTop: '75%',
    position: 'relative',
    '& span': {
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
  },
}))

export const DetailPage = () => {
  const { user } = useSession()
  const { id } = useParams()
  const classes = useStyles()
  const [paper, setPaper] = useState({})
  const db = getFirestore()
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const fetchPaper = useCallback(
    async (_id) => {
      setLoading(true)
      const docRef = doc(db, 'papers', _id)
      const docSnap = await getDoc(docRef)
      setPaper(docSnap.data())
      setLoading(false)
    }, [db],
  )

  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )

  const handleApprove = useCallback(
    async () => {
      try {
        const docRef = doc(db, 'papers', id)
        await updateDoc(docRef, {
          status: 'publish',
        })
        enqueueSnackbar('อนุมัติสำเร็จ', {
          variant: 'success',
        })
        fetchPaper(id)
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [db, fetchPaper, closeDialog, id, enqueueSnackbar],
  )

  const handleReject = useCallback(
    async () => {
      try {
        const docRef = doc(db, 'papers', id)
        await updateDoc(docRef, {
          status: 'reject',
        })
        enqueueSnackbar('ไม่อนุมัติสำเร็จ', {
          variant: 'success',
        })
        fetchPaper(id)
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [db, fetchPaper, closeDialog, id, enqueueSnackbar],
  )

  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'approve') {
          return <ApproveDialog onSubmit={handleApprove} onClose={closeDialog} paper={dialog.data} />
        }
        if (dialog.mode === 'reject') {
          return <RejectDialog onSubmit={handleReject} onClose={closeDialog} paper={dialog.data} />
        }
        if (dialog.mode === 'preview') {
          return <LightBoxDialog onClose={closeDialog} image={dialog.data} />
        }
      }
      return null
    },
    [closeDialog, dialog, handleReject, handleApprove],
  )

  useEffect(
    () => {
      fetchPaper(id)
    }, [id, fetchPaper],
  )
  const handleDownload = () => {
    const url = paper.paperFile.data || paper.fileRef
    window.open(url, '_blank')
  }
  if (loading) {
    return <Loading />
  }
  return (
    <Container maxWidth="md" style={{ marginTop: 54 }}>
      <Box>
        <Box sx={{ my: 3 }} style={{ textAlign: 'center' }}>
          <Typography variant="h4">
            {paper.paperName}
          </Typography>
        </Box>
        {paper.images && (
          <Box style={{ textAlign: 'center' }}>
            <img
              className={classes.topImage}
              src={paper.images[0].image}
              alt="cover"
            />
          </Box>
        )}
        <Box display="flex" sx={{ my: 3 }} justifyContent="end">
          {user && user.role === 'admin' && paper.status === 'pending' && (
          <Box sx={{ mx: 2 }}>
            <Button variant="outlined" color="primary" onClick={openDialog('approve', paper)} style={{ marginRight: 10 }}>
              อนุมัติ
            </Button>
            <Button variant="outlined" color="secondary" onClick={openDialog('reject', paper)}>
              ไม่อนุมัติ
            </Button>
          </Box>
          )}
          <Button variant="contained" color="primary" onClick={() => handleDownload()}>
            เอกสารวิจัยฉบับเต็ม
          </Button>
        </Box>
        {paper.name && (
          <p>
            <Typography className={classes.topic} variant="h6">
              นักวิจัย
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.name}
            </Typography>
          </p>
        )}
        {paper.agency && (
          <p>
            <Typography className={classes.topic} variant="h6">
              หน่วยงาน
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.agency}
            </Typography>
          </p>
        )}
        {paper.techType && (
          <p>
            <Typography className={classes.topic} variant="h6">
              รูปแบบเทคโนโลยี
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.techType}
            </Typography>
          </p>
        )}
        {paper.intellectualProperty && (
          <p>
            <Typography className={classes.topic} variant="h6">
              ทรัพย์สินทางปัญญา
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.intellectualProperty || paper.intellectualDetail}
            </Typography>
          </p>
        )}
        {paper.description && (
          <p>
            <Typography className={classes.topic} variant="h6">
              ที่มา ข้อมูลเบื้องต้น ความสำคัญของปัญหา <br />
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.description}
            </Typography>
          </p>
        )}
        {paper.conclusion && (
          <p>
            <Typography className={classes.topic} variant="h6">
              จุดเด่นเทคโนโลยี
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.conclusion}
            </Typography>
          </p>
        )}
        {paper.target && (
          <p>
            <Typography className={classes.topic} variant="h6">
              ตลาด/กลุ่มเป้าหมาย
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.target}
            </Typography>
          </p>
        )}
        {paper?.images?.length > 1 && (
          <Box>
            <Grid container style={{ width: '70%', margin: 'auto' }} spacing={1} justifyContent="center">
              {paper.images.slice(1).map(({ image }) => (
                <Grid item xs={6} key={image}>
                  <Paper className={classes.layout_img}>
                    <Box component="span" style={{ cursor: 'pointer' }} onClick={openDialog('preview', image)}>
                      <img key={image} src={image} alt={image} />
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            {paper.imageRef && (
            <Box display="flex" justifyContent="center">
              <Typography variant="caption">
                <span className={classes.topic}>ที่มารูปภาพ: </span>
                {paper.imageRef}
              </Typography>
            </Box>
            )}
          </Box>
        )}
        {paper.modelType && (
          <p>
            <Typography className={classes.topic} variant="h6">
              ความพร้อมของเทคโนโลยี
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.modelType}
            </Typography>
          </p>
        )}
        {paper.levelTech && (
          <p>
            <Typography className={classes.topic} variant="h6">
              ระดับของเทคโนโลยี
            </Typography>
            <Typography className={classes.content} variant="body1">
              {paper.levelTech}
            </Typography>
          </p>
        )}
        <p>
          <Typography className={classes.topic} variant="h6">
            สนใจสอบถามข้อมูล
          </Typography>
          <Typography className={classes.content} variant="body1">
            {paper.contact && <div>{paper.contact}</div>}
            {paper.phone && <div>{paper.phone}</div>}
            {paper.email && <div>{paper.email}</div>}
          </Typography>
        </p>
      </Box>
      <Box sx={{ my: 3 }} display="flex" justifyContent="center">
        {user && user.role === 'admin' && paper.status === 'pending' && (
        <Box sx={{ mx: 2 }}>
          <Button variant="outlined" color="primary" onClick={openDialog('approve', paper)} style={{ marginRight: 10 }}>
            อนุมัติ
          </Button>
          <Button variant="outlined" color="secondary" onClick={openDialog('reject', paper)}>
            ไม่อนุมัติ
          </Button>
        </Box>
        )}
        <Button variant="contained" color="primary" onClick={() => handleDownload()}>
          เอกสารวิจัยฉบับเต็ม
        </Button>
      </Box>
      {renderDialog}
    </Container>
  )
}
