import {
  Box, Button, Container, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core'
import {
  Delete as DeleteIcon, Edit as EditIcon, Search as SearchIcon, Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons'
import Promise from 'bluebird'
import {
  collection, doc, getDocs, getFirestore, query, setDoc, updateDoc, where, deleteDoc,
} from 'firebase/firestore'
import {
  deleteObject, getDownloadURL, getStorage, ref, uploadBytes, uploadString,
} from 'firebase/storage'
import _omit from 'lodash/omit'
import moment from 'moment'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { uid } from 'uid'
import { useDebounce } from 'use-debounce/lib'
import { useSnackbar } from 'notistack'

import { AddPaperDialog, DeletePaperDialog, VisibilityPaperDialog } from '../components/Dialogs/FormDialog'
import { useSession } from '../contexts/SessionContext'

const paperRecordHelper = (data) => {
  const { _id, ...record } = data
  return {
    _id,
    record,
  }
}

export const MyPaperPage = () => {
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })
  const [defaultList, setDefaultList] = useState([])
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const [debounce] = useDebounce(search, 700)
  const { enqueueSnackbar } = useSnackbar()

  const db = getFirestore()
  const storage = getStorage()
  const { user } = useSession()

  const fetchData = useCallback(
    async () => {
      const q = query(collection(db, 'papers'), where('createBy', '==', user.uid))
      const querySnaphot = await getDocs(q)
      const results = []
      querySnaphot.forEach((document) => results.push({ _id: document.id, ...document.data() }))
      setList(results)
      setDefaultList(results)
    }, [db, user.uid],
  )
  useEffect(
    () => {
      fetchData()
    }, [fetchData],
  )
  useEffect(
    () => {
      if (!debounce) {
        setList(defaultList)
      }
      const results = defaultList.filter((item) => {
        const paperNameLowercase = item.paperName.toLowerCase()
        const nameLowercase = item.name.toLowerCase()
        const statusLowercase = item.status.toLowerCase()

        const paperName = paperNameLowercase.includes(debounce)
        const name = nameLowercase.includes(debounce)
        const status = statusLowercase.includes(debounce)

        return paperName || name || status
      })
      setList(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce],
  )
  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )
  const uploadImageToGs = useCallback(
    async (data) => {
      if (!data.image.includes('base64')) {
        return data
      }
      try {
        const storageRef = ref(storage, data.name)
        const base64 = data.image.replace(`data:${data.mime};base64,`, '')
        const snapshot = await uploadString(storageRef, base64, 'base64', {
          cacheControl: 'public,max-age=300',
          contentType: data.mime,
        })
        const downloadUrl = await getDownloadURL(snapshot.ref)
        return { image: downloadUrl, name: data.name, mime: data.mime }
      } catch (err) {
        console.error(err)
      }
      return null
    }, [storage],
  )
  const uploadFileToGs = useCallback(
    async ({ data: bytes, mime, name }) => {
      if (typeof bytes !== 'object') {
        return null
      }
      try {
        const storageRef = ref(storage, name)
        const snapshot = await uploadBytes(storageRef, bytes, {
          contentType: mime,
        })
        const downloadUrl = await getDownloadURL(snapshot.ref)
        return { data: downloadUrl, name, mime }
      } catch (err) {
        console.error(err)
      }
      return null
    }, [storage],
  )
  const handleCreatePaper = useCallback(
    async (data) => {
      try {
        const id = uid(16)
        const { images, paperFile } = data
        const file = paperFile ? await uploadFileToGs({ ...paperFile, name: `${id}/${paperFile.name}` }) : ''
        const imagesUrls = await Promise.map(images, (image) => uploadImageToGs({ ...image, name: `${id}/${image.name}` }))
        const { record } = paperRecordHelper({
          ...data, createAt: Date.now(), status: 'pending', images: imagesUrls, paperFile: file,
        })
        await setDoc(doc(db, 'papers', id), { ...record })
        enqueueSnackbar('เพิ่มงานวิจัยสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    },
    [db, closeDialog, fetchData, uploadImageToGs, uploadFileToGs, enqueueSnackbar],
  )
  const deleteFileInGs = useCallback(
    async (name) => {
      const storageRef = ref(storage, name)
      return deleteObject(storageRef).catch((err) => console.error(err))
    }, [storage],
  )
  const handleUpdatePaper = useCallback(
    async (data) => {
      try {
        if (data.deleteFile?.length > 0) {
          await Promise.each(data.deleteFile, (file) => deleteFileInGs(file))
        }
        const file = await uploadFileToGs({ ...data.paperFile, name: `${data._id}/${data.paperFile.name}` })
        const imagesUrls = await Promise.map(data.images, (image) => uploadImageToGs({ ...image, name: `${data._id}/${image.name}` }))
        const dataOmited = _omit(data, 'deleteFile')
        const { _id, record } = paperRecordHelper({
          ...dataOmited,
          updateAt: Date.now(),
          paperFile: file || dataOmited.paperFile,
          images: imagesUrls[0] ? imagesUrls : dataOmited.images,
        })
        const docRef = doc(db, 'papers', _id)
        await updateDoc(docRef, record)
        enqueueSnackbar('แก้ไขสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    },
    [closeDialog, db, fetchData, deleteFileInGs, uploadFileToGs, uploadImageToGs, enqueueSnackbar],
  )
  const handleDeletePaper = useCallback(
    async (data) => {
      const { _id, record } = paperRecordHelper(data)
      try {
        if (record.images?.length > 0) {
          await Promise.each(record.images, (image) => deleteFileInGs(image.name))
        }
        if (record.paperFile?.name) {
          await deleteFileInGs(record.paperFile.name)
        }
        const docRef = doc(db, 'papers', _id)
        await deleteDoc(docRef)
        enqueueSnackbar('ลบสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [closeDialog, fetchData, db, deleteFileInGs, enqueueSnackbar],
  )
  const handlePublishPaper = useCallback(
    async (data) => {
      try {
        const { _id, record: { status } } = paperRecordHelper(data)
        const docRef = doc(db, 'papers', _id)
        await updateDoc(docRef, { status })
        const msg = status === 'publish' ? 'เผยแพร่' : 'ซ่อน'
        enqueueSnackbar(`${msg}สำเร็จ`, {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [db, closeDialog, fetchData, enqueueSnackbar],
  )
  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'add') {
          return <AddPaperDialog onSubmit={handleCreatePaper} onClose={closeDialog} />
        }
        if (dialog.mode === 'edit') {
          return <AddPaperDialog paper={dialog.data} onSubmit={handleUpdatePaper} onClose={closeDialog} />
        }
        if (dialog.mode === 'delete') {
          return <DeletePaperDialog paper={dialog.data} onSubmit={handleDeletePaper} onClose={closeDialog} />
        }
        if (dialog.mode === 'publish') {
          return <VisibilityPaperDialog paper={dialog.data} onSubmit={handlePublishPaper} onClose={closeDialog} />
        }
      }
      return null
    },
    [closeDialog, dialog, handleCreatePaper, handleUpdatePaper, handleDeletePaper, handlePublishPaper],
  )
  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value)
    }, [],
  )
  return (
    <Container maxWidth="md" style={{ marginTop: 24 }}>
      <Typography variant="h5">
        งานวิจัยของคุณ
      </Typography>
      <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          style={{ marginRight: 10 }}
          onClick={openDialog('add')}
        >
          เพิ่มงานวิจัย
        </Button>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>งานวิจัย</TableCell>
              <TableCell>ชื่อนักวิจัย</TableCell>
              <TableCell>วันที่สร้าง</TableCell>
              <TableCell>สถานะ</TableCell>
              <TableCell align="center" width={140}>จัดการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.paperName}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{moment(item.createAt).format('lll')}</TableCell>
                <TableCell style={{ textTransform: 'uppercase' }}>{item.status}</TableCell>
                <TableCell align="center" sx={{ py: 1 }}>
                  {(item.status === 'publish' || item.status === 'hidden') && (
                  <IconButton size="small" onClick={openDialog('publish', item)}>
                    {item.status === 'publish' ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                  )}
                  <IconButton size="small" onClick={openDialog('edit', item)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton size="small" onClick={openDialog('delete', item)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {list.length === 0 && (
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          ไม่มีข้อมูล
        </Box>
      )}
      <Box sx={{ my: 2 }}>
        <Typography variant="h6">
          จำนวนรายการผลงานวิจัยรวม {list.length} รายการ
        </Typography>
      </Box>
      {renderDialog}
    </Container>
  )
}
