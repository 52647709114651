/* eslint-disable no-unused-vars */
import {
  Box, Container, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core'
import { Person as PersonIcon, PersonAddDisabled as PersonAddDisabledIcon, Search as SearchIcon } from '@material-ui/icons'
import {
  collection, doc, getDocs, getFirestore, query, updateDoc,
} from 'firebase/firestore'
import { useSnackbar } from 'notistack'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useDebounce } from 'use-debounce/lib'

import { ChangeRoleDialog } from '../components/Dialogs/UserDialog'
import { useSession } from '../contexts/SessionContext'

const ManageUser = () => {
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })
  const [defaultList, setDeafultList] = useState([])
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const [debounce] = useDebounce(search, 700)
  const { user } = useSession()
  const { enqueueSnackbar } = useSnackbar()

  const db = getFirestore()

  const fetchData = useCallback(
    async () => {
      const q = query(collection(db, 'users'))
      const querySnaphot = await getDocs(q)
      const results = []
      querySnaphot.forEach((document) => results.push({ _id: document.id, ...document.data() }))
      setList(results.filter((item) => item.uid !== user.uid))
      setDeafultList(results)
    }, [db, user.uid],
  )
  useEffect(
    () => {
      fetchData()
    }, [fetchData],
  )
  useEffect(
    () => {
      if (!debounce) {
        setList(defaultList)
      }
      const results = defaultList.filter((item) => {
        const emailLowercase = item.email.toLowerCase()
        const roleLowercase = item.role.toLowerCase()

        const email = emailLowercase.includes(debounce)
        const role = roleLowercase.includes(debounce)

        return email || role
      })
      setList(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce],
  )
  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )

  const handleChangeRole = useCallback(
    async (data) => {
      try {
        const docRef = doc(db, 'users', data.uid)
        await updateDoc(docRef, {
          role: data.role,
        })
        enqueueSnackbar('เปลี่ยนบทบาทสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [closeDialog, fetchData, db, enqueueSnackbar],
  )

  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'role') {
          return <ChangeRoleDialog onSubmit={handleChangeRole} onClose={closeDialog} user={dialog.data} />
        }
      }
      return null
    },
    [closeDialog, dialog, handleChangeRole],
  )
  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value)
    }, [],
  )
  return (
    <Container maxWidth="md" style={{ marginTop: 24 }}>
      <Typography variant="h5">
        จัดการผู้ใช้
      </Typography>
      <Box display="flex" justifyContent="end" sx={{ my: 2 }}>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>อีเมล</TableCell>
              <TableCell>บทบาท</TableCell>
              <TableCell align="center" width={140}>จัดการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item.uid}>
                <TableCell>{item.email}</TableCell>
                <TableCell>{item.role}</TableCell>
                <TableCell align="center" sx={{ py: 1 }}>
                  <IconButton size="small" onClick={openDialog('role', item)}>
                    {item.role === 'admin' ? <PersonAddDisabledIcon /> : <PersonIcon />}
                  </IconButton>
                  {/* <IconButton size="small" onClick={openDialog('delete', item)}>
                    <DeleteIcon />
                  </IconButton> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {list.length === 0 && (
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          ไม่มีข้อมูล
        </Box>
      )}
      <Box sx={{ my: 2 }}>
        <Typography variant="h6">
          จำนวนทั้งหมด {list.length} รายการ
        </Typography>
      </Box>
      {renderDialog}
    </Container>
  )
}

export default ManageUser
