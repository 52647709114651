import {
  Box, Container, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core'
import {
  Check as CheckIcon, ChromeReaderModeRounded as ReaderMore, Clear as ClearIcon, Search as SearchIcon,
} from '@material-ui/icons'
import {
  collection, doc, getDocs, getFirestore, query, updateDoc, where,
} from 'firebase/firestore'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { useDebounce } from 'use-debounce/lib'

import { ApproveDialog, RejectDialog } from '../components/Dialogs/ReviewDialog'

const paperRecordHelper = (data) => {
  const { _id, ...record } = data
  return {
    _id,
    record,
  }
}

export const ReviewPage = () => {
  const history = useHistory()
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })
  const [defaultList, setDeafultList] = useState([])
  const [list, setList] = useState([])
  const [search, setSearch] = useState('')
  const [debounce] = useDebounce(search, 700)
  const { enqueueSnackbar } = useSnackbar()

  const db = getFirestore()

  const fetchData = useCallback(
    async () => {
      const q = query(collection(db, 'papers'), where('status', '==', 'pending'))
      const querySnaphot = await getDocs(q)
      const results = []
      querySnaphot.forEach((document) => results.push({ _id: document.id, ...document.data() }))
      setList(results)
      setDeafultList(results)
    }, [db],
  )
  useEffect(
    () => {
      fetchData()
    }, [fetchData],
  )
  useEffect(
    () => {
      if (!debounce) {
        setList(defaultList)
      }
      const results = defaultList.filter((item) => {
        const paperNameLowercase = item.paperName.toLowerCase()
        const nameLowercase = item.name.toLowerCase()
        const statusLowercase = item.status.toLowerCase()

        const paperName = paperNameLowercase.includes(debounce)
        const name = nameLowercase.includes(debounce)
        const status = statusLowercase.includes(debounce)

        return paperName || name || status
      })
      setList(results)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debounce],
  )
  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )

  const handleApprove = useCallback(
    async (data) => {
      const { _id } = paperRecordHelper(data)
      try {
        const docRef = doc(db, 'papers', _id)
        await updateDoc(docRef, {
          status: 'publish',
        })
        enqueueSnackbar('อนุมัติสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [closeDialog, fetchData, db, enqueueSnackbar],
  )

  const handleReject = useCallback(
    async (data) => {
      const { _id } = paperRecordHelper(data)
      try {
        const docRef = doc(db, 'papers', _id)
        await updateDoc(docRef, {
          status: 'reject',
        })
        enqueueSnackbar('ไม่อนุมัติสำเร็จ', {
          variant: 'success',
        })
        fetchData()
        closeDialog()
      } catch (err) {
        console.error(err)
        enqueueSnackbar('มีบางอย่างผิดพลาด กรุณาลองใหม่', { variant: 'error' })
      }
    }, [closeDialog, fetchData, db, enqueueSnackbar],
  )

  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'approve') {
          return <ApproveDialog onSubmit={handleApprove} onClose={closeDialog} paper={dialog.data} />
        }
        if (dialog.mode === 'reject') {
          return <RejectDialog onSubmit={handleReject} onClose={closeDialog} paper={dialog.data} />
        }
      }
      return null
    },
    [closeDialog, dialog, handleReject, handleApprove],
  )
  const handleSearch = useCallback(
    (e) => {
      setSearch(e.target.value)
    }, [],
  )
  return (
    <Container maxWidth="md" style={{ marginTop: 24 }}>
      <Typography variant="h5">
        งานวิจัยที่ต้องรีวิว
      </Typography>
      <Box display="flex" justifyContent="end" sx={{ my: 2 }}>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>งานวิจัย</TableCell>
              <TableCell>ชื่อนักวิจัย</TableCell>
              <TableCell>วันที่สร้าง</TableCell>
              <TableCell>สถานะ</TableCell>
              <TableCell align="center" width={140}>จัดการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((item) => (
              <TableRow key={item._id}>
                <TableCell>{item.paperName}</TableCell>
                <TableCell>{item.name}</TableCell>
                <TableCell>{moment(item.createAt).format('lll')}</TableCell>
                <TableCell style={{ textTransform: 'uppercase' }}>{item.status}</TableCell>
                <TableCell align="center" sx={{ py: 1 }}>
                  <IconButton size="small" onClick={() => history.push(`/detail/${item._id}`)}>
                    <ReaderMore />
                  </IconButton>
                  <IconButton size="small" onClick={openDialog('approve', item)}>
                    <CheckIcon />
                  </IconButton>
                  <IconButton size="small" onClick={openDialog('reject', item)}>
                    <ClearIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {list.length === 0 && (
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          ไม่มีข้อมูล
        </Box>
      )}
      <Box sx={{ my: 2 }}>
        <Typography variant="h6">
          จำนวนทั้งหมด {list.length} รายการ
        </Typography>
      </Box>
      {renderDialog}
    </Container>
  )
}
