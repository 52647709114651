import {
  Box, Button, Container, Typography,
} from '@material-ui/core'

export const ReviewDetailPage = () => (
  <Container maxWidth="md" style={{ marginTop: 12 }}>
    <Box>
      <Typography variant="h4">
        การพัฒนาผลิตภัณฑ์กัมมี่เยลลี่ใบรางจืด
      </Typography>
      <img src="https://source.unsplash.com/random" height={200} width="100%" alt="cover" />
      <Box display="flex" p={1}>
        <Box flexGrow={1}>
          <Button variant="contained" color="primary">
            เอกสารวิจัยฉบับเต็ม
          </Button>
        </Box>
        <Box>
          <Button variant="contained" color="primary" style={{ marginRight: 5 }}>
            อนุมัติ
          </Button>
          <Button variant="contained" color="secondary">
            ไม่อนุมัติ
          </Button>
        </Box>
      </Box>
      <p>
        <Typography variant="h6">
          นักวิจัย
        </Typography>
        ธีรวรรณ สุวรรณ์ <br />ณัฐธยาน์ ชูสุข <br />จันทิมา ภูงามเงิน
      </p>

      <p>
        <Typography variant="h6">
          หน่วยงาน
        </Typography>
        มหาวิทยาลัยเทคโนโลยีพระจอมเกล้าพระนครเหนือ
      </p>

      <p>
        <Typography variant="h6">
          รูปแบบเทคโนโลยี
        </Typography>
        อาหารและเครื่องดื่ม
      </p>
      <p>
        <Typography variant="h6">
          ตลาด/กลุ่มเป้าหมาย
        </Typography>
        ผลิตแผ่นมาส์กหน้า แผ่นปิดปาดแผล แคปซูลยา สารเคลือบผักผลไม้ จะสามารถยืดอายุการเก็บรักษาสินค้าเกษตร
      </p>
      <p>
        <Typography variant="h6">
          ที่มา ข้อมูลเบื้องต้น ความสำคัญของปัญหา <br />
        </Typography>
        รางจืดเป็นสมุนไพรที่มีประโยชน์ มีคุณสมบัติเด่นคือ สามารถล้างพิษได้ ดังนั้นจึงต้องการพัฒนาผลิตภัณฑ์ให้มีความหลากหลาย สามารถบริโภคได้ทั้งผู้สูงอายุ ผู้ใหญ่ วัยรุ่น และเด็ก โดยสามารถต่อ ยอดเชิงพาณิชย์ได้ง่าย เพราะใช้กระบวนการผลิตที่ไม่ซับซ้อน ต้นทุนการผลิตต่ำ
      </p>

      <p>
        <Typography variant="h6">
          สรุปและจุดเด่นเทคโนโลยี
        </Typography>
        ได้ผลิตภัณฑ์ใหม่สำหรับคนรุ่นใหม่ และพัฒนาผลิตภัณฑ์จากสมุนไพรพื้นบ้านที่มีประโยชน์
      </p>

      <p>
        <Typography variant="h6">
          รูปแบบความพร้อมของเทคโนโลยี
        </Typography>
        ต้นแบบผลิตภัณฑ์
      </p>

      <p>
        <Typography variant="h6">
          ระดับของเทคโนโลยี
        </Typography>
        ระดับห้องปฏิบัติการ
      </p>

      <p>
        <Typography variant="h6">
          การเผยแพร่ผลงานงานวิจัย
        </Typography>
        วารสารวิชาการระดับชาติ
      </p>

      <p>
        <Typography variant="h6">
          รายละเอียดทรัพย์สินทางปัญญา
        </Typography>
        เลขคำขอ1603001705 เลขที่สิทธิบัตร 13512
      </p>
      <p>
        <Typography variant="h6">
          สนใจสอบถามข้อมูล
        </Typography>
        ผู้ช่วยศาสตราจารย์ ดร.จันทิมา ภูงามเงิน <br />
        เบอร์ติดต่อ <br />
        0818116501
        email <br />
        chanthima.p@agro.kmutnb.ac.th
      </p>
    </Box>
    <Box display="flex" p={1}>
      <Box flexGrow={1}>
        <Button variant="contained" color="primary">
          เอกสารวิจัยฉบับเต็ม
        </Button>
      </Box>
      <Box>
        <Button variant="contained" color="primary" style={{ marginRight: 5 }}>
          อนุมัติ
        </Button>
        <Button variant="contained" color="secondary">
          ไม่อนุมัติ
        </Button>
      </Box>
    </Box>
  </Container>
)
