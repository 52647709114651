/* eslint-disable no-shadow */
/* eslint-disable no-restricted-syntax */
import {
  Box,
  Button,
  Dialog,
  DialogActions, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, ImageListItemBar, MenuItem, Paper, Step, StepLabel, Stepper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import Promise from 'bluebird'
import _pickBy from 'lodash/pickBy'
import _pullAt from 'lodash/pullAt'
import { useSnackbar } from 'notistack'
import {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useForm } from 'react-hook-form'
import XLSX from 'xlsx'

import { registerHelper } from '../../utils'
import { Transition } from '../Common/Transition'
import { useSession } from '../../contexts/SessionContext'

export const AddPaperDialog = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const {
    paper = {
      paperName: '',
      name: '',
      agency: '',
      techType: '',
      otherTechType: '',
      intellectualProperty: '',
      intellectualDetail: '',
      publishType: '',
      otherPublishType: '',
      description: '',
      conclusion: '',
      modelType: '',
      levelTech: '',
      imageRef: '',
      fileRef: '',
      target: '',
      contact: '',
      phone: '',
      email: '',
    }, onClose, onSubmit,
  } = props
  const [activeStep, setActiveStep] = useState(0)
  const [isEdit, setIsEdit] = useState(false)
  const {
    watch, setValue, getValues, register, handleSubmit, trigger, setError, clearErrors, formState: { errors, isValid, isSubmitting },
  } = useForm({ defaultValues: paper })
  const { user } = useSession()

  useEffect(
    () => {
      if (paper?._id) {
        setIsEdit(true)
      } else {
        register('createBy', { value: user.uid })
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paper],
  )
  const handleChangeImages = useCallback(
    async (e) => {
      const list = getValues('images') || []
      for (const [, file] of Object.entries(e.target.files)) {
        const fileType = file.type
        const fileSize = file.size
        if (fileType === 'image/png' || fileType === 'image/jpeg') {
          if (fileSize <= 1e+7) {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = () => {
              const base64 = reader.result
              list.push({
                image: base64,
                name: file.name,
                mime: fileType,
              })
              setValue('images', list.slice(-5))
            }
          } else {
            enqueueSnackbar(`ไฟล์ ${file.name}ใหญ่เกิน 10 MB`, {
              variant: 'error',
            })
          }
        } else {
          enqueueSnackbar('รองรับเฉพาะไฟล์ png และ jpg', {
            variant: 'error',
          })
        }
      }
    }, [setValue, getValues, enqueueSnackbar],
  )

  const handleChangePdf = useCallback(
    async (e) => {
      const file = e.target.files[0]
      const fileType = file.type
      const fileSize = file.size
      if (fileType === 'application/pdf') {
        if (fileSize <= 1e+7) {
          const fileReader = new FileReader()
          fileReader.readAsArrayBuffer(file)
          // eslint-disable-next-line func-names
          fileReader.onload = function () {
            // eslint-disable-next-line react/no-this-in-sfc
            const typedArray = new Uint8Array(this.result)
            setValue('paperFile', {
              name: file.name,
              mime: fileType,
              data: typedArray,
            })
          }
          if (isEdit) {
            const deleteFile = getValues('deleteFile') || []
            const fileName = getValues('paperFile')?.name
            if (fileName?.includes(paper._id)) {
              deleteFile.push(fileName)
              setValue('deleteFile', deleteFile)
            }
          }
        } else {
          enqueueSnackbar(`ไฟล์ ${file.name}ใหญ่เกิน 10 MB`, {
            variant: 'error',
          })
        }
      } else {
        enqueueSnackbar('รองรับเฉพาะไฟล์ pdf', {
          variant: 'error',
        })
      }
    }, [enqueueSnackbar, setValue, getValues, isEdit, paper],
  )

  const handleRemoveImage = useCallback(
    async (index) => {
      const list = getValues('images')
      const pulled = _pullAt(list, index)
      if (isEdit) {
        const deleteFile = getValues('deleteFile') || []
        const fileName = pulled[0].name
        if (fileName?.includes(paper._id)) {
          deleteFile.push(fileName)
          setValue('deleteFile', deleteFile)
        }
      }
      if (!getValues('images') || getValues('images')?.length === 0) {
        setError('images', {
          type: 'manual',
          message: 'โปรดระบุรูปผลงานวิจัยอย่างน้อย 1 รูป',
        })
      } else {
        clearErrors('images')
      }
      setValue('images', list)
    },
    [getValues, setValue, paper, isEdit, clearErrors, setError],
  )

  const handleNext = useCallback(
    async () => {
      await trigger()
      if (activeStep === 1) {
        if (!getValues('images') || getValues('images')?.length === 0) {
          setError('images', {
            type: 'manual',
            message: 'โปรดระบุรูปผลงานวิจัยอย่างน้อย 1 รูป',
          })
        } else {
          clearErrors('images')
        }
      }
      if (isValid && Object.keys(errors).length === 0) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1)
      }
    }, [isValid, activeStep, getValues, setError, trigger, clearErrors, errors],
  )

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <>
            <TextField
              variant="outlined"
              autoFocus
              margin="dense"
              label="ชื่อผลงาน/ชื่อเรื่องงานวิจัย"
              fullWidth
              required
              error={!!errors.paperName}
              helperText={errors.paperName?.message}
              {...registerHelper(register('paperName', { required: 'โปรดระบุใส่ชื่อผลงานหรือชื่อเรื่องงานวิจัย' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="ชื่อ-สกุล นักวิจัย"
              fullWidth
              required
              error={!!errors.name}
              helperText={errors.name?.message || '(หากมีนักวิจัยหลายท่านให้ใส่เครื่องหมาย ,)'}
              {...registerHelper(register('name', { required: 'โปรดระบุใส่ชื่อ-สกุล นักวิจัย' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="ชื่อหน่วยงาน"
              fullWidth
              required
              error={!!errors.agency}
              helperText={errors.agency?.message}
              {...registerHelper(register('agency', { required: 'โปรดระบุใส่ชื่อหน่วยงาน' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="รูปแบบเทคโนโลยี"
              fullWidth
              select
              defaultValue={paper?.techType ? paper?.techType : getValues('techType')}
              required
              error={!!errors.techType}
              helperText={errors.techType?.message}
              {...registerHelper(register('techType', { required: 'โปรดเลือกรูปแบบเทคโนโลยี' }))}
            >
              <MenuItem value="อาหารและเครื่องดื่ม">อาหารและเครื่องดื่ม</MenuItem>
              <MenuItem value="เกษตร/ประมง">เกษตร/ประมง</MenuItem>
              <MenuItem value="ไฟฟ้า/อิเล็กทรอนิกส์/ดิจิทัล">ไฟฟ้า/อิเล็กทรอนิกส์/ดิจิทัล</MenuItem>
              <MenuItem value="เวชสำอาง/เวชภัณฑ์">เวชสำอาง/เวชภัณฑ์</MenuItem>
              <MenuItem value="การแพทย์">การแพทย์</MenuItem>
              <MenuItem value="เครื่องจักรและยานยนต์">เครื่องจักรและยานยนต์</MenuItem>
              <MenuItem value="เคมีภัณฑ์">เคมีภัณฑ์</MenuItem>
              <MenuItem value="กระดาษและการพิมพ์">กระดาษและการพิมพ์</MenuItem>
              <MenuItem value="พลาสติก">พลาสติก</MenuItem>
              <MenuItem value="สิ่งทอ">สิ่งทอ</MenuItem>
              <MenuItem value="การตลาด/ประชาสัมพันธ์">การตลาด/ประชาสัมพันธ์</MenuItem>
              <MenuItem value="อัญมณีและเครื่องประดับ">อัญมณีและเครื่องประดับ</MenuItem>
              <MenuItem value="เชื้อเพลิงชีวภาพ">เชื้อเพลิงชีวภาพ</MenuItem>
              <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
            </TextField>
            {watch('techType') === 'อื่นๆ' && (
              <TextField
                variant="outlined"
                margin="dense"
                label="โปรดระบุรูปแบบเทคโนโลยี"
                fullWidth
                required
                error={!!errors.otherTechType}
                helperText={errors.otherTechType?.message}
                {...registerHelper(register('otherTechType', { required: 'โปรดระบุรูปแบบเทคโนโลยี' }))}
              />
            )}
            <TextField
              variant="outlined"
              margin="dense"
              label="ข้อมูลด้านทรัพย์สินทางปัญญา"
              fullWidth
              select
              required
              defaultValue={paper.intellectualProperty ? paper.intellectualProperty : getValues('intellectualProperty')}
              error={!!errors.intellectualProperty}
              helperText={errors.intellectualProperty?.message}
              {...registerHelper(register('intellectualProperty', { required: 'โปรดเลือกข้อมูลด้านทรัพย์สินทางปัญญา' }))}
            >
              <MenuItem value="อนุสิทธิบัตร">อนุสิทธิบัตร</MenuItem>
              <MenuItem value="สิทธิบัตร">สิทธิบัตร</MenuItem>
              <MenuItem value="ลิขสิทธิ์">ลิขสิทธิ์</MenuItem>
              <MenuItem value="เครื่องหมายทางการค้า">เครื่องหมายทางการค้า</MenuItem>
              <MenuItem value="ความลับทางการค้า">ความลับทางการค้า</MenuItem>
              <MenuItem value="พันธุ์พืช/พันธุ์สัตว์">พันธุ์พืช/พันธุ์สัตว์</MenuItem>
              <MenuItem value="อยู่ระหว่างดำเนินการ">อยู่ระหว่างดำเนินการ</MenuItem>
              <MenuItem value="ยังไม่ได้ดำเนินการ">ยังไม่ได้ดำเนินการ</MenuItem>
            </TextField>
            <TextField
              variant="outlined"
              margin="dense"
              label="รายละเอียดทรัพย์สินทางปัญญา"
              fullWidth
              helperText="กรุณาระบุข้อมูล เลขที่ วันที่ และเรื่องทรัพย์สินทางปัญญา"
              {...registerHelper(register('intellectualDetail'))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="การเผยแพร่ผลงานงานวิจัย"
              fullWidth
              select
              defaultValue={paper.publishType ? paper.publishType : getValues('publishType')}
              required
              error={!!errors.publishType}
              helperText={errors.publishType?.message}
              {...registerHelper(register('publishType', { required: 'โปรดเลือกการเผยแพร่ผลงานงานวิจัย' }))}
            >
              <MenuItem value="วารสารวิชาการระดับชาติ">วารสารวิชาการระดับชาติ</MenuItem>
              <MenuItem value="วารสารวิชาการระดับนานาชาติ">วารสารวิชาการระดับนานาชาติ</MenuItem>
              <MenuItem value="หนังสือ">หนังสือ</MenuItem>
              <MenuItem value="การประชุมเผยแพร่ผลงาน/สัมมนาระดับชาติ">การประชุมเผยแพร่ผลงาน/สัมมนาระดับชาติ</MenuItem>
              <MenuItem value="การประชุมเผยแพร่ผลงาน/สัมมนาระดับนานาชาติ">การประชุมเผยแพร่ผลงาน/สัมมนาระดับนานาชาติ</MenuItem>
              <MenuItem value="ยังไม่ได้ดำเนินการ">ยังไม่ได้ดำเนินการ</MenuItem>
              <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
            </TextField>
            {watch('publishType') === 'อื่นๆ' && (
              <TextField
                variant="outlined"
                margin="dense"
                label="โปรดระบุการเผยแพร่ผลงานงานวิจัย"
                fullWidth
                required
                error={!!errors.otherPublishType}
                helperText={errors.otherPublishType?.message}
                {...registerHelper(register('otherPublishType', { required: 'โปรดระบุการเผยแพร่ผลงานงานวิจัย' }))}
              />
            )}
          </>
        )
      case 1:
        return (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">รายละเอียดด้านข้อมูลการวิจัยและเทคโนโลยี</Typography>
              <Typography variant="caption">คำชี้แจง : กรุณากรอกข้อมูลที่เกี่ยวข้องกับเทคโนโลยีงานวิจัยและจุดเด่นของงานวิจัยของท่าน</Typography>
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="ที่มา ข้อมูลเบื้องต้น ความสำคัญของปัญหา"
              autoFocus
              fullWidth
              multiline
              rows={3}
              required
              error={!!errors.description}
              helperText={errors.description?.message}
              {...registerHelper(register('description', { required: 'โปรดระบุที่มา ข้อมูลเบื้องต้น ความสำคัญของปัญหา' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="สรุปและจุดเด่นเทคโนโลยี"
              fullWidth
              multiline
              rows={3}
              required
              error={!!errors.conclusion}
              helperText={errors.conclusion?.message}
              {...registerHelper(register('conclusion', { required: 'โปรดระบุสรุปและจุดเด่นเทคโนโลยี' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="รูปแบบความพร้อมของเทคโนโลยี"
              fullWidth
              select
              defaultValue={paper.modelType ? paper.modelType : getValues('modelType')}
              required
              error={!!errors.modelType}
              helperText={errors.modelType?.message}
              {...registerHelper(register('modelType', { required: 'โปรดเลือกรูปแบบความพร้อมของเทคโนโลยี' }))}
            >
              <MenuItem value="ต้นแบบผลิตภัณฑ์">ต้นแบบผลิตภัณฑ์</MenuItem>
              <MenuItem value="ต้นแบบเทคโนโลยี">ต้นแบบเทคโนโลยี</MenuItem>
              <MenuItem value="กระบวนการใหม่">กระบวนการใหม่</MenuItem>
              <MenuItem value="ผลงานนวัตกรรม">ผลงานนวัตกรรม</MenuItem>
              <MenuItem value="เทคโนโลยี/ผลิตภัณฑ์ พร้อมถ่ายทอด">เทคโนโลยี/ผลิตภัณฑ์ พร้อมถ่ายทอด</MenuItem>
            </TextField>
            <TextField
              variant="outlined"
              margin="dense"
              label="ระดับของเทคโนโลยี"
              fullWidth
              select
              defaultValue={paper.levelTech ? paper.levelTech : getValues('levelTech')}
              required
              error={!!errors.levelTech}
              helperText={errors.levelTech?.message}
              {...registerHelper(register('levelTech', { required: 'โปรดระบุระดับของเทคโนโลยี' }))}
            >
              <MenuItem value="ระดับห้องปฏิบัติการ">ระดับห้องปฏิบัติการ</MenuItem>
              <MenuItem value="ระดับภาคสนาม">ระดับภาคสนาม</MenuItem>
              <MenuItem value="ระดับอุตสาหกรรม">ระดับอุตสาหกรรม</MenuItem>
            </TextField>
            <Box sx={{ my: 1 }}>
              <Box style={{
                display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden', marginBottom: 4,
              }}
              >
                <ImageList style={{ flexWrap: 'nowrap', transform: 'translateZ(0)' }} cols={2.5}>
                  {watch('images') && watch('images').map((image, index) => (
                    <ImageListItem key={image.image}>
                      <img src={image.image} alt="cover" />
                      <ImageListItemBar
                        style={{ background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)' }}
                        title={image.name}
                        actionIcon={(
                          <IconButton onClick={() => handleRemoveImage(index)}>
                            <DeleteIcon htmlColor="red" />
                          </IconButton>
                        )}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Box>
              <Button variant="outlined" color={!errors.images ? 'primary' : 'secondary'} size="small" component="label" sx={{ mr: 2 }}>
                เลือกรูปภาพแสดงผลงานวิจัย
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleChangeImages}
                  accept="image/png, image/jpeg"
                  multiple
                />
              </Button>
              <Box>
                {!errors.images ? (
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: 14 }}>
                    (รองรับไฟล์ชนิด png, jpg จำนวน ไม่เกิน 5 ไฟล์ ขนาด 10 MB  เช่น ภาพผลิตภัณฑ์ )
                  </Typography>
                ) : (
                  <Typography variant="caption" style={{ color: '#f44336', marginLeft: 14 }}>
                    {errors.images.message}
                  </Typography>
                )}
              </Box>
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="แหล่งที่มารูปภาพผลงาน"
              fullWidth
              required
              error={!!errors.imageRef}
              helperText={errors.imageRef?.message || '(กรุณาระบุ link หรือแหล่งที่มาของรูปภาพผลงานหรือเทคโนโลยี)'}
              {...registerHelper(register('imageRef', { required: 'โปรดระบุแหล่งที่มารูปภาพผลงาน' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="ลิงก์สำหรับดาวน์โหลดงานวิจัย"
              fullWidth
              required
              error={!!errors.fileRef}
              helperText={errors.fileRef?.message || '(กรุณาระบุ URL เอกสารงานวิจัยฉบับเต็ม หรือ ข้อมูลสรุปผลงานวิจัย)'}
              {...registerHelper(register('fileRef', { required: 'โปรดระบุลิงก์สำหรับดาวน์โหลดงานวิจัย' }))}
            />
            <Box sx={{ my: 1 }}>
              <Box display="flex" alignItems="center">
                <Button variant="outlined" color={!errors.paperFile ? 'primary' : 'secondary'} size="small" component="label" style={{ minWidth: 158 }} sx={{ mr: 2 }}>
                  เลือกไฟล์เอกสารงานวิจัย
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleChangePdf}
                    accept="application/pdf"
                  />
                </Button>
                {watch('paperFile') && (
                <Typography variant="body1" style={{ marginLeft: 12 }}>
                  {watch('paperFile').name}
                </Typography>
                )}
              </Box>
              <Box>
                {!errors.paperFile ? (
                  <Typography variant="caption" style={{ color: 'rgba(0, 0, 0, 0.54)', marginLeft: 14 }}>
                    (รองรับในรูปแบบ pdf ไฟล์ จำนวน 1 ไฟล์ ขนาด 10 MB และสามารถเผยแพร่ได้โดยไม่มีลิขสิทธิ์)
                  </Typography>
                ) : (
                  <Typography variant="caption" style={{ color: '#f44336', marginLeft: 14 }}>
                    {errors.paperFile.message}
                  </Typography>
                )}
              </Box>
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="ตลาด/กลุ่มเป้าหมาย"
              fullWidth
              required
              error={!!errors.target}
              helperText={errors.target?.message}
              {...registerHelper(register('target', { required: 'โปรดระบุตลาดหรือกลุ่มเป้าหมาย' }))}
            />
          </>
        )
      case 2:
        return (
          <>
            <Box sx={{ mb: 2 }}>
              <Typography variant="h6">การติดต่อนักวิจัย</Typography>
              <Typography variant="caption">คำชี้แจง: กรุณาระบุรายละเอียดช่องทางการติดต่อนักวิจัย</Typography>
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              label="ชื่อหรือหน่วยงานสำหรับการติดต่อผู้วิจัย"
              autoFocus
              fullWidth
              required
              error={!!errors.contact}
              helperText={errors.contact?.message}
              {...registerHelper(register('contact', { required: 'โปรดระบุชื่อหรือหน่วยงานสำหรับการติดต่อผู้วิจัย' }))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="เบอร์ติดต่อ"
              fullWidth
              {...registerHelper(register('phone'))}
            />
            <TextField
              variant="outlined"
              margin="dense"
              label="อีเมล"
              fullWidth
              required
              error={!!errors.email}
              helperText={errors.email?.message}
              {...registerHelper(register('email', { required: 'โปรดระบุอีเมล' }))}
            />
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <Dialog open maxWidth="sm" fullWidth>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{isEdit ? 'แก้ไข' : 'เพิ่ม'}งานวิจัย</DialogTitle>
        <DialogContent dividers>
          <Stepper activeStep={activeStep}>
            {['เบื้องต้น', 'รายละเอียดด้านข้อมูลการวิจัยและเทคโนโลยี', 'การติดต่อนักวิจัย'].map((label) => {
              const stepProps = {}
              const labelProps = {}
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps} />
                </Step>
              )
            })}
          </Stepper>
          <Box style={{ overflow: 'hidden' }}>
            {getStepContent(activeStep)}
            <Box textAlign="center" style={{ marginTop: 12 }}>
              {activeStep > 0 && (
                <Button variant="outlined" color="primary" style={{ marginRight: 12 }} onClick={() => handleBack()}>
                  ย้อนกลับ
                </Button>
              )}
              {activeStep < 2 && (
                <Button variant="outlined" color="primary" onClick={() => handleNext()}>
                  ถัดไป
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            ยกเลิก
          </Button>
          {activeStep === 2 && (
          <Button type="submit" color="primary" disabled={isSubmitting}>
            {isEdit ? 'บันทึก' : 'สร้าง'}
          </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const VisibilityPaperDialog = (props) => {
  const { paper, onSubmit, onClose } = props
  const [mode, setMode] = useState('')
  const { handleSubmit, setValue } = useForm({ defaultValues: paper })
  useEffect(
    () => {
      if (paper.status === 'publish') {
        setValue('status', 'hidden')
        setMode('ซ่อน')
      } else {
        setValue('status', 'publish')
        setMode('แสดง')
      }
    }, [paper, setValue],
  )
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{mode}งานวิจัย</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ยืนยัน{mode} ({paper?.paperName}) ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            {mode}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export const DeletePaperDialog = (props) => {
  const { paper, onSubmit, onClose } = props
  const { handleSubmit } = useForm({ defaultValues: paper })
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>ลบงานวิจัย</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ยืนยันลบ ({paper.paperName}) ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            ลบ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const ImportPaperDialog = (props) => {
  const { onSubmit, onClose } = props
  const [papers, setPapers] = useState([])
  const [filename, setFilename] = useState(null)
  const { setValue, handleSubmit, formState: { isSubmitting } } = useForm({ defaultValues: { papers: [] } })
  const keys = useCallback(
    () => ([
      { keyword: 'Timestamp', objectKey: 'createAt' },
      { keyword: 'Email Address', objectKey: 'emailAddress' },
      { keyword: 'ชื่อผลงาน/ชื่อเรื่องงานวิจัย', objectKey: 'paperName' },
      { keyword: 'ชื่อ-สกุล นักวิจัย', objectKey: 'name' },
      { keyword: 'ชื่อหน่วยงาน', objectKey: 'agency' },
      { keyword: 'รูปแบบเทคโนโลยี', objectKey: 'techType' },
      { keyword: 'ข้อมูลด้านทรัพย์สินทางปัญญา', objectKey: 'intellectualProperty' },
      { keyword: 'รายละเอียดทรัพย์สินทางปัญญา', objectKey: 'intellectualDetail' },
      { keyword: 'การเผยแพร่ผลงานงานวิจัย', objectKey: 'publishType' },
      { keyword: 'ที่มา ข้อมูลเบื้องต้น ความสำคัญของปัญหา', objectKey: 'description' },
      { keyword: 'สรุปและจุดเด่นเทคโนโลยี', objectKey: 'conclusion' },
      { keyword: 'รูปแบบความพร้อมของเทคโนโลยี', objectKey: 'modelType' },
      { keyword: 'ระดับของเทคโนโลยี', objectKey: 'levelTech' },
      { keyword: 'รูปภาพแสดงผลงานวิจัย', objectKey: 'images' },
      { keyword: 'แหล่งที่มารูปภาพผลงาน', objectKey: 'imageRef' },
      { keyword: 'link สำหรับดาวน์โหลดงานวิจัย', objectKey: 'fileRef' },
      { keyword: 'ไฟล์เอกสารงานวิจัย', objectKey: 'paperFile' },
      { keyword: 'ตลาด/กลุ่มเป้าหมาย', objectKey: 'target' },
      { keyword: 'ชื่อหรือหน่วยงานสำหรับการติดต่อผู้วิจัย', objectKey: 'contact' },
      { keyword: 'เบอร์ติดต่อ', objectKey: 'phone' },
      { keyword: 'email', objectKey: 'email' },
      { keyword: 'กรณีระบุรูปแบบเทคโนโลยีเป็นอื่นๆ', objectKey: 'otherTechType' },
      { keyword: 'กรณีเลือกการเผยแพร่ผลงานงานวิจัย แบบอื่นๆ', objectKey: 'otherPublishType' },
    ]), [],
  )
  const handleSelectFile = useCallback(
    (e) => {
      const [file] = e.target.files
      if (file) {
        const reader = new FileReader()
        reader.onload = async (ev) => {
          const data = new Uint8Array(ev.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          const records = XLSX.utils.sheet_to_json(worksheet)
          const results = await Promise.mapSeries(records, (record) => Promise.reduce(keys(), async (pre, { keyword, objectKey }) => {
            const picked = _pickBy(record, (e, i) => i.includes(keyword))

            if (keyword === 'รูปภาพแสดงผลงานวิจัย') { // if current is image field
              const splited = Object.values(picked)[0].split(',')
              const images = await Promise.mapSeries(splited, async (image) => {
                const params = (new URL(image)).searchParams
                const id = params.get('id')
                return {
                  image: `https://drive.google.com/uc?id=${id}`,
                }
              })
              return Object.assign(pre, { [objectKey]: images })
            }
            if (keyword === 'ไฟล์เอกสารงานวิจัย') {
              if (Object.values(picked)[0]) {
                const params = (new URL(Object.values(picked)[0])).searchParams
                const id = params.get('id')
                return Object.assign(pre, { [objectKey]: `https://drive.google.com/uc?id=${id}` })
              }
            }
            return Object.assign(pre, { [objectKey]: Object.values(picked)[0] || '' })
          }, {}))
          setFilename(file.name)
          setPapers(results)
          setValue('papers', results)
          setValue('filename', file.name)
        }
        reader.readAsArrayBuffer(file)
      }
    },
    [setValue, keys],
  )
  const renderPreview = useMemo(
    () => {
      if (papers.length > 0) {
        return (
          <TableContainer component={Paper} sx={{ border: '1px solid', borderColor: 'divider' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ลำดับ</TableCell>
                  <TableCell>ชื่องานวิจัย</TableCell>
                  <TableCell>ชื่อผู้วิจัย</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {papers.map((customer, index) => (
                  <TableRow key={customer.name}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{customer.paperName}</TableCell>
                    <TableCell>{customer.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )
      }
      return null
    },
    [papers],
  )
  return (
    <Dialog open fullScreen maxWidth="xs" TransitionComponent={Transition} onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <DialogTitle>นำเข้างานวิจัย</DialogTitle>
        <DialogContent dividers sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Button variant="contained" size="small" component="label" sx={{ mr: 2 }}>
              เลือกไฟล์ .xlsx
              <input type="file" style={{ display: 'none' }} onChange={handleSelectFile} />
            </Button>
            <Typography variant="body1" style={{ marginLeft: 12 }}>{filename}</Typography>
          </Box>
          {renderPreview}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Import
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
