import {
  Box,
  Button,
  Dialog,
  DialogActions, DialogContent, DialogTitle, TextField, Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'

import { registerHelper } from '../../utils'

export const LoginDialog = (props) => {
  const {
    email = '', onClose, onSubmit, resetPassword, loading,
  } = props
  const [reCaptcha, setReCaptcha] = useState('')
  const {
    register, setValue, handleSubmit, formState: { errors },
  } = useForm({ defaultValues: { email } })

  useEffect(
    () => {
      setValue('reCaptcha', reCaptcha)
    }, [reCaptcha, setValue],
  )

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>เข้าสู่ระบบ</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label="อีเมล"
            type="email"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
            {...registerHelper(register('email', { required: 'กรุณาใส่อีเมล' }))}
          />
          <TextField
            margin="dense"
            label="รหัสผ่าน"
            fullWidth
            type="password"
            variant="outlined"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...registerHelper(register('password', { required: 'กรุณาใส่รหัสผ่าน' }))}
          />
          <Box display="flex" justifyContent="center" style={{ marginTop: 12 }}>
            <Button onClick={resetPassword}>ลืมรหัสผ่าน</Button>
          </Box>
          <Box display="flex" justifyContent="center" marginTop={2}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCA}
              onChange={(e) => setReCaptcha(e)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            ยกเลิก
          </Button>
          <Button variant="contained" type="submit" color="primary" disabled={(!reCaptcha || loading)}>
            เข้าสู่ระบบ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const RegisterDialog = (props) => {
  const { onSubmit, onClose, loading } = props
  const [reCaptcha, setReCaptcha] = useState('')
  const {
    watch, setValue, register, handleSubmit, formState: { errors },
  } = useForm()

  useEffect(
    () => {
      setValue('reCaptcha', reCaptcha)
    }, [reCaptcha, setValue],
  )

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>ลงทะเบียน</DialogTitle>
        <DialogContent dividers>
          <TextField
            type="email"
            autoFocus
            margin="dense"
            label="อีเมล"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
            {...registerHelper(register('email', { required: 'กรุณาใส่อีเมล' }))}
          />
          <TextField
            margin="dense"
            label="สร้างรหัสผ่าน"
            fullWidth
            variant="outlined"
            type="password"
            error={!!errors.password}
            helperText={errors.password?.message}
            {...registerHelper(register('password', { required: 'กรุณาใส่รหัสผ่าน' }))}
          />
          <TextField
            margin="dense"
            label="ยืนยันรหัสผ่าน"
            fullWidth
            type="password"
            variant="outlined"
            error={!!errors.confirm}
            helperText={errors.confirm?.message}
            {...registerHelper(register('confirm', {
              required: 'กรุณายืนยันรหัสผ่าน',
              validate: (value) => value === watch('password') || 'รหัสผ่านไม่ตรงกัน',
            }))}
          />
          <Box display="flex" justifyContent="center" marginTop={2}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCA}
              onChange={(e) => setReCaptcha(e)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            ยกเลิก
          </Button>
          <Button variant="contained" color="primary" type="submit" disabled={(!reCaptcha || loading)}>
            ลงทะเบียน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const ResetPasswordDialog = (props) => {
  const { onClose, onSubmit } = props
  const {
    register, handleSubmit, formState: { errors },
  } = useForm()

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>เปลี่ยนรหัสผ่าน</DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="dense"
            label="อีเมล"
            type="email"
            variant="outlined"
            fullWidth
            error={!!errors.email}
            helperText={errors.email?.message}
            {...registerHelper(register('email', { required: 'กรุณาใส่อีเมล' }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            ยกเลิก
          </Button>
          <Button variant="contained" type="submit" color="primary">
            ส่ง
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const ChangeRoleDialog = (props) => {
  const { user, onSubmit, onClose } = props
  const [mode, setMode] = useState('')
  const { handleSubmit, setValue } = useForm({ defaultValues: user })
  useEffect(
    () => {
      if (user.role === 'admin') {
        setValue('role', 'editor')
        setMode('ผู้ใช้ทั่วไป')
      } else {
        setValue('role', 'admin')
        setMode('แอดมิน')
      }
    }, [user, setValue],
  )
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>เปลี่ยนบทบาท</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ยืนยันการเปลี่ยนบทบาท ({user?.email}) เป็น{mode} ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
          >
            ยืนยัน
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export const DeleteUserDialog = (props) => {
  const { user, onSubmit, onClose } = props
  const { handleSubmit } = useForm({ defaultValues: user })
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>ลบผู้ใช้</DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6">ยืนยันลบ ({user.email}) ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>ยกเลิก</Button>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
          >
            ลบ
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
