import {
  Box, Button, Container, IconButton, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
} from '@material-ui/core'
import { Search as SearchIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { useCallback, useMemo, useState } from 'react'

import { AddPaperDialog } from '../components/Dialogs/FormDialog'

export const PaperPage = () => {
  const [dialog, setDialog] = useState({ open: false, mode: null, data: null })

  const openDialog = useCallback(
    (mode, data) => () => {
      setDialog({ open: true, mode, data })
    },
    [],
  )
  const closeDialog = useCallback(
    () => {
      setDialog({ open: false, mode: null, data: null })
    },
    [],
  )
  const renderDialog = useMemo(
    () => {
      if (dialog.open) {
        if (dialog.mode === 'add') {
          return <AddPaperDialog onSubmit={() => {}} onClose={closeDialog} />
        }
      }
      return null
    },
    [closeDialog, dialog],
  )
  return (
    <Container maxWidth="md" style={{ marginTop: 24 }}>
      <Typography variant="h5">
        จัดการงานวิจัยทั้งหมด
      </Typography>
      <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
        <Box>
          <Button variant="contained" color="primary" size="small" style={{ marginRight: 10 }}>
            เพิ่มงานวิจัย
          </Button>
          <Button variant="contained" color="primary" size="small" style={{ marginRight: 10 }}>
            นำเข้า
          </Button>
        </Box>
        <TextField
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Create At</TableCell>
              <TableCell align="center" width={140}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>xxx</TableCell>
              <TableCell>xxx</TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                <IconButton size="small" onClick={openDialog('edit')}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>xxx</TableCell>
              <TableCell>xxx</TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                <IconButton size="small" onClick={openDialog('edit')}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>xxx</TableCell>
              <TableCell>xxx</TableCell>
              <TableCell align="center" sx={{ py: 1 }}>
                <IconButton size="small" onClick={openDialog('edit')}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ my: 2 }}>
        <Typography variant="h6">
          จำนวนรายการผลงานวิจัยรวม 12 รายการ
        </Typography>
      </Box>
      {renderDialog}
    </Container>
  )
}
