import { lazy, Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import moment from 'moment'

import Loading from './components/Common/Loading'
import Layout from './components/Layout'
import { useSession } from './contexts/SessionContext'
import { AllPaperPage } from './pages/AllPaperPage'
import { DetailPage } from './pages/DetailPage'
import { MyPaperPage } from './pages/MyPaperPage'
import { PaperPage } from './pages/PaperPage'
import { ReviewDetailPage } from './pages/ReviewDetailPage'
import { ReviewPage } from './pages/ReviewPage'
import ScrollToTop from './components/Common/ScrollToTop'
import ManageUser from './pages/ManageUser'
import AboutUsPage from './pages/AboutUsPage'

moment().locale('th')

const HomePage = lazy(() => import('./pages/HomePage'))

const App = () => (
  <Layout>
    <Suspense fallback={<Loading />}>
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/about" component={AboutUsPage} />
        <Route path="/detail/:id" component={DetailPage} />
        <PrivateRoute path="/paper" component={MyPaperPage} />
        <PrivateRoute path="/manage-paper" component={PaperPage} />
        <AdminRoute path="/all" component={AllPaperPage} />
        <AdminRoute path="/users" component={ManageUser} />
        <AdminRoute path="/review-paper" component={ReviewPage} />
        <AdminRoute path="/review-detail" component={ReviewDetailPage} />
      </Switch>
    </Suspense>
  </Layout>
)

const PrivateRoute = (props) => {
  const { children, component: Component, ...rest } = props
  const { user, checkAuth } = useSession()
  if (checkAuth) {
    return <Loading />
  }
  return (
    <Route
      {...rest}
      render={() => {
        if (!user) {
          return (
            <Redirect to={{ pathname: '/' }} />
          )
        }
        if (Component) {
          return <Component />
        }
        return children
      }}
    />
  )
}

const AdminRoute = (props) => {
  const { children, component: Component, ...rest } = props
  const { user, checkAuth } = useSession()
  if (checkAuth) {
    return <Loading />
  }
  return (
    <Route
      {...rest}
      render={() => {
        if (user?.role !== 'admin') {
          return (
            <Redirect to={{ pathname: '/' }} />
          )
        }
        if (Component) {
          return <Component />
        }
        return children
      }}
    />
  )
}

export default App
