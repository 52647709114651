import { Dialog, DialogContent } from '@material-ui/core'

export const LightBoxDialog = (props) => {
  const { image, onClose } = props
  return (
    <Dialog open fullWidth maxWidth="xs" onClose={onClose}>
      <DialogContent dividers>
        {image && <img src={image} width="100%" alt={image} />}
      </DialogContent>
    </Dialog>
  )
}
