import { CircularProgress, Container, Typography } from '@material-ui/core'

const Loading = () => (
  <Container
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
    style={{
      textAlign: 'center',
      marginTop: 50,
    }}
  >
    <CircularProgress color="primary" disableShrink />
    <Typography variant="h5" style={{ marginTop: 12 }}>Loading ...</Typography>
  </Container>
)

export default Loading
